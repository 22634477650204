import {
  ChakraProvider,
  HStack,
  Icon,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import "./App.css";
import FraudCard from "./components/FraudCard";

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Show dialog when component mounts
  React.useEffect(() => {
    onOpen();
  }, [onOpen]); // Empty dependency array means this runs once when component mounts

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black bg-opacity-80 backdrop-blur-sm transition-opacity"></div>

          <div className="flex min-h-full items-center justify-center p-4">
            <div className="relative w-full md:w-1/2 transform overflow-hidden rounded-2xl bg-white shadow-2xl transition-all md:min-w-[700px] mx-auto">
              {/* Header */}
              <div className="relative border-b border-gray-100 bg-white px-4 sm:px-6 py-4 sm:py-6">
                <h2 className="text-xl sm:text-2xl font-bold text-gray-800">
                  Important Update
                </h2>
                <button
                  onClick={onClose}
                  className="absolute right-4 sm:right-6 top-4 sm:top-6 text-gray-500 hover:text-gray-700"
                >
                  <svg
                    className="h-5 w-5 sm:h-6 sm:w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              {/* Body */}
              <div className="bg-gray-50 px-4 sm:px-6 py-4 sm:py-6">
                <div className="space-y-4 sm:space-y-6">
                  <p className="text-lg text-gray-700 leading-relaxed">
                    Mario has been saying that he will repay the money but
                    nothing substantial was delivered, so we viewed these claims
                    as lies to make us reluctant to take legal action against
                    him.
                  </p>

                  <p className="text-lg text-gray-700 leading-relaxed">
                    On Jan 29th, Mario reached out to Haytham (one of the
                    victims defrauded and the maker of this website) and told
                    him that he will start repaying the amount taken. He claimed
                    that he was lied to and was a victim of defrauding himself.
                    Whether this is true or not, remains unclear. Regardless,
                    the money is still owed. <br /> <br /> However, on the 30th
                    of January, Mario sent 2000 euros. The agreement was to give
                    him a second chance as he repays the loan. I decided to
                    share this message transparently to anyone accessing this.
                    The information written is my own subjective understanding
                    based on what happened. I am just a skeptical spectator at
                    the time. But every time Mario repays something, I will add
                    it below. accessing this. The information written is my own
                    subjective understanding based on what happened. I am just a
                    skeptical spectator at the time. <br /> <br /> Everytime
                    Mario repays something, I will add it below:
                  </p>

                  {/* Table container with horizontal scroll for mobile */}
                  <div className="overflow-x-auto -mx-4 sm:mx-0">
                    <div className="inline-block min-w-full align-middle">
                      <div className="overflow-hidden rounded-xl bg-white shadow-sm border border-gray-100">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-white">
                            <tr>
                              <th className="px-6 py-4 text-left text-md font-medium text-gray-700">
                                Date
                              </th>
                              <th className="px-6 py-4 text-right text-md font-medium text-gray-700">
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            <tr>
                              <td className="px-6 py-4 text-gray-700">
                                13 March 2024
                              </td>
                              <td className="px-6 py-4 text-right text-gray-700">
                                626.16
                              </td>
                            </tr>
                            <tr>
                              <td className="px-6 py-4 text-gray-700">
                                16 April 2024
                              </td>
                              <td className="px-6 py-4 text-right text-gray-700">
                                300.00
                              </td>
                            </tr>
                            <tr>
                              <td className="px-6 py-4 text-gray-700">
                                11 June 2024
                              </td>
                              <td className="px-6 py-4 text-right text-gray-700">
                                600.00
                              </td>
                            </tr>
                            <tr>
                              <td className="px-6 py-4 text-gray-700">
                                30 Jan 2025
                              </td>
                              <td className="px-6 py-4 text-right text-gray-700">
                                2,000.00
                              </td>
                            </tr>
                            <tr>
                              <td className="px-6 py-4 text-gray-700">
                                3 March 2025 (
                                {Math.floor(
                                  Math.abs(
                                    new Date("2025-03-03").getTime() -
                                      Date.now()
                                  ) /
                                    (1000 * 60 * 60 * 24)
                                )}{" "}
                                days ago)
                              </td>
                              <td className="px-6 py-4 text-right text-gray-700">
                                440.00
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <p className="text-base sm:text-lg font-bold text-gray-800">
                    Total amount paid: $3,966.16 out of $120,000.00
                  </p>
                  <p className="text-base sm:text-lg font-bold text-red-600">
                    Remaining amount: $116,033.84
                  </p>

                  <p className="text-base sm:text-lg">
                    You can watch this video for full story from my point of
                    view:{" "}
                    <a
                      href="https://youtu.be/MXKQWQvF_Ok"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 font-semibold hover:text-blue-600 transition-colors break-all"
                    >
                      https://youtu.be/MXKQWQvF_Ok
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ChakraProvider>
        <FraudCard
          fraudsterName="Mario Grasbock"
          fraudsterImage={require("../src/assets/mario2.png")}
          estimatedMoneyLost="$550K - 2M"
          socialMediaLinks={
            <HStack spacing={4} mt={2}>
              <Link href="https://www.youtube.com/channel/EXAMPLE" isExternal>
                <Icon as={FaYoutube} boxSize={6} color="red.500" />
              </Link>
              <Link href="https://www.instagram.com/EXAMPLE" isExternal>
                <Icon as={FaInstagram} boxSize={6} color="purple.500" />
              </Link>
              <Link href="https://www.linkedin.com/in/EXAMPLE" isExternal>
                <Icon as={FaLinkedin} boxSize={6} color="blue.500" />
              </Link>
            </HStack>
          }
          fraudsterDescription={
            <Text>
              Mario Grasbock is a con-artist, a fraudster who committed many
              defrauding and money laundering crimes. He is a skilled
              manipulator and displays signs of
              <Link
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4321752/#:~:text=Psychopathy%20is%20a%20neuropsychiatric%20disorder,antisocial%20deviance%20and%20criminal%20behavior."
                isExternal
                color="blue.600"
              >
                {" "}
                psychopathy
              </Link>{" "}
              or
              <Link
                href="https://www.mayoclinic.org/diseases-conditions/antisocial-personality-disorder/symptoms-causes/syc-20353928#:~:text=Antisocial%20personality%20disorder%2C%20sometimes%20called,rights%20and%20feelings%20of%20others."
                isExternal
                color="blue.600"
              >
                {" "}
                sociopathy
              </Link>{" "}
              such as lack of remorse, cunning manipulation, lack of empathy
              (yet having an uncanny ability to mimic it), chronic lying and
              impulsivity. <br /> <br />
              Mario has defrauded multiple people claiming that he is an
              investor trying to help them, with a pure intention of building a
              wellness center to heal people and animals. All of these turned
              out to be lies. What we know as of now is that Mario builds trust
              to exploit his victims. Keep in mind, Mario is really skilled at
              lying and manipulation and can sound truthful but his actions
              don't match his words.
            </Text>
          }
          partners={[
            {
              name: "Matthew Lobene",
            },
            {
              name: "Joshua Staurt Koch",
              socialHandleLink:
                "https://www.linkedin.com/in/josh-s-koch-0529379b",
            },

            {
              name: "Rafael Lopes Brasileiro Martim",
            },
          ]}
          spouses={[
            {
              name: "@_mrs_leela",
              socialHandleLink: "https://www.instagram.com/_mrs_leela/",
              ex: true,
            },
          ]}
          techniques={[
            "Compliments and love-bombing of victims.",
            "Long cons after building up trust over time.",
            "Sending fake balance and transfer bank statements.",
            "Meditating with his victims, and having shared experiences to build trust.",
            "Identifying a need that the victim desires and faking the ability to provide it.",
            "Use of a fictious story about his desire to build a healing foundation.",
          ]}
          documents={[
            require("./assets/pdfs/mario/Mario.pdf"),
            require("./assets/pdfs/mario/DBFraud-Notice.pdf"),
            require("./assets/pdfs/mario/GeoCoreFakeContract.pdf"),
            require("./assets/pdfs/mario/ANDRASTER-CIS.pdf"),
            require("./assets/pdfs/mario/fakeBalance.pdf"),
          ]}
          images={[
            require("./assets/mario1.jpeg"),
            require("./assets/mario3.png"),
            require("./assets/mario4.png"),
          ]}
          voiceSamples={[
            require("./assets/audio/mario1.ogg"),
            require("./assets/audio/mario2.ogg"),
          ]}
          educationalMedia={[
            {
              displayName: "What is a psychopath?",
              link: "https://www.verywellmind.com/what-is-a-psychopath-5025217",
            },
            {
              displayName: "Narcissist, Psychopath, or Sociopath?",
              link: "https://www.youtube.com/watch?v=6dv8zJiggBs&ab_channel=MedCircle",
            },
            {
              displayName: "7 Characteristics of the Modern Psychopath",
              link: "https://www.psychologytoday.com/us/blog/communication-success/201810/7-characteristics-of-the-modern-psychopath",
            },
            {
              displayName: "Four ways to spot a con artist",
              link: "https://www.cnbc.com/2020/01/03/four-sure-fire-ways-to-spot-a-con-artistand-what-to-do-when-you-see-them.html",
            },
          ]}
        />
      </ChakraProvider>
    </>
  );
}

export default App;
